import IconsVisa from '~icons/icons/Visa.svg'
import IconsMastercard from '~icons/icons/Mastercard.svg'
import IconsAmericanExpress from '~icons/icons/AmericanExpress.svg'
import IconsMaestro from '~icons/icons/Maestro.svg'
import IconsJcb from '~icons/icons/JCB.svg'
import IconsVisaElectron from '~icons/icons/VisaElectron.svg'
import IconsPayPal from '~icons/icons/PayPal.svg'

export type IconType = keyof typeof iconsMap

export const iconsMap = {
  visa: IconsVisa,
  mastercard: IconsMastercard,
  americanExpress: IconsAmericanExpress,
  maestro: IconsMaestro,
  jcb: IconsJcb,
  visaElectron: IconsVisaElectron,
  payPal: IconsPayPal,
}
