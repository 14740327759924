export const useBrandSocials = (brand: ComputedRef<string>) => {
  const deliveryKey = computed(() => 'brand-socials-' + brand.value)

  const localePath = useLocalePath()

  const { data } = useFetch('/api/cms/getBrandSocials', {
    query: {
      locale: localePath(''),
      deliveryKey: deliveryKey,
    },
    getCachedData: (key, nuxtApp) => nuxtApp.payload.data[key],
    dedupe: 'defer',
    watch: [deliveryKey],
  })

  return data
}
